.modal-pdpa .btn,
.modal-pdpa .btn.btn-link i {
    vertical-align: middle
}

.modal-pdpa .title {
    display: block;
    margin-bottom: 8px;
    font-weight: 700;
    line-height: normal
}

.modal-pdpa .title-header {
    color: #454950;
    margin-top: 0;
    margin-bottom: 24px
}

.modal-pdpa .title-header small {
    display: block;
    margin-top: 8px;
    color: #7e8ea4;
    font-size: 12px;
    font-weight: 400
}

.modal-pdpa .btn,
.modal-pdpa .btn-sm {
    font-weight: 600;
    border-style: solid;
    white-space: nowrap
}

.modal-pdpa p {
    color: #586575
}

.modal-pdpa ul {
    list-style: disc;
    padding-left: 24px;
    margin: 0 0 16px
}

.modal-pdpa ul:last-child {
    margin-bottom: 0
}

.modal-pdpa ul li ul {
    padding-left: 16px;
    list-style: circle;
    margin: 0
}

.modal-pdpa a {
    text-decoration: none;
    cursor: pointer;
    color: #009EFB
}

.modal-pdpa a:active,
.modal-pdpa a:focus,
.modal-pdpa a:hover {
    text-decoration: underline !important
}

.modal-pdpa .btn,
.modal-pdpa a:focus,
.modal-pdpa button {
    outline: 0 !important;
    box-shadow: none !important
}

.modal-pdpa [class*=btn-]+[class*=btn-] {
    margin-left: 16px
}

.modal-pdpa .btn+.btn,
.modal-pdpa .btn-sm+.btn-sm {
    margin-left: 8px
}

.modal-pdpa .btn {
    min-width: 120px;
    height: 40px;
    font-size: 14px;
    padding: 0 16px;
    border-radius: 4px;
    border-width: 2px;
    line-height: 2;
    display: inline-block;
    text-align: center;
    user-select: none;
    background-color: transparent;
    cursor: pointer
}

.modal-pdpa .btn [type=file] {
    display: none
}

.modal-pdpa .btn.disabled {
    opacity: inherit
}

.modal-pdpa .btn-sm {
    padding: 0 12px;
    height: 24px;
    border-width: 1px;
    min-width: 80px;
    line-height: normal
}

.modal-pdpa .btn-sm.btn-icon {
    padding: 0 6px
}

.modal-pdpa [class*=btn-] {
    white-space: nowrap
}

.modal-pdpa [class*=btn-] [class*=icon-] {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -2px
}

.modal-pdpa .btn-primary {
    background: #28a745;
    border-color: #28a745;
    color: #fff
}

.modal-pdpa .btn-primary [class*=icon-] {
    color: #fff
}

.modal-pdpa .btn-noborder {
    border: 0;
    background: 0 0;
    padding: 0 8px;
    min-width: auto;
    width: auto;
    color: #586575
}

.modal-pdpa .lang {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px 16px;
    border-top: 1px solid #dedede
}

.modal-pdpa .lang .dropdown-toggle [class*=icon-] {
    color: #586575;
    margin-right: 12px
}

.modal-pdpa .lang .inline-dropdown {
    position: relative;
    display: inline-block;
    border-radius: 4px
}

.modal-pdpa .lang .noborder {
    background: 0 0
}

.modal-pdpa .lang .noborder .dropdown-toggle {
    border: none
}

.modal-pdpa .lang .dropdown-toggle {
    max-width: initial;
    border: 0px solid #dedede;
    line-height: 1;
    border-radius: 4px;
    font-weight: 400;
    min-width: 24px;
    text-align: left;
    width: 100%;
    position: relative;
    min-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #f3f4f5;
}

.modal-pdpa .lang .dropdown-toggle:after {
    font-family: icomoon-pdpa;
    content: "\e9abc";
    border: none;
    font-size: 12px;
    position: initial;
    top: 8px;
    right: 8px;
    margin-left: 0;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    text-align: center;
    line-height: 24px
}

.modal-pdpa .lang .dropdown-menu {
    border: none;
    padding: 8px 0;
    margin: 4px 0 !important;
    border-radius: 4px;
    max-height: 256px;
    min-width: 100%;
    overflow-x: auto;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    text-align: left;
    list-style: none;
    background: #fff
}

.modal-pdpa .lang .dropdown-menu .dropdown-item {
    padding: 12px;
    color: #586575;
    white-space: normal;
    display: block;
    width: 100%;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    text-decoration: none;
    cursor: pointer
}

.modal-pdpa .lang .dropdown-menu .dropdown-item:hover {
    color: #586575;
    background: #e6e8eb
}

.modal-pdpa .lang .dropdown-menu .dropdown-item.active {
    color: #586575;
    background: #eaf6ff
}

.modal-pdpa .lang .dropdown-menu .dropdown-item:before {
    display: none
}

.modal-pdpa .show>.dropdown-toggle {
    background: #e6e8eb
}

.modal-pdpa .show.dropdown-menu {
    display: block
}

.modal-pdpa .inline-dropdown {
    width: initial;
    max-width: initial
}

.modal-pdpa [class^=icon-] {
    font-size: 20px;
    position: relative;
    color: #7e8ea4
}

.modal-pdpa [class^=icon-lg-] {
    font-size: 24px
}

.modal-pdpa [class^=icon-sm-] {
    font-size: 16px;
    height: 16px
}

@keyframes anim-rotate {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

.modal-pdpa .spinner {
    display: inline-block;
    font-size: 20.2px;
    line-height: 1;
    animation: anim-rotate 2s infinite linear;
    animation: anim-rotate .75s infinite steps(8)
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    12.5% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }
    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }
    37.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg)
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
    62.5% {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg)
    }
    75% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }
    87.5% {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    12.5% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }
    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }
    37.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg)
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
    62.5% {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg)
    }
    75% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }
    87.5% {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.modal-pdpa .icon-spin {
    -webkit-animation: spin 1s infinite teps(8);
    animation: spin 1s infinite steps(8);
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    line-height: 1 !important
}

.modal-pdpa .icon-spin span {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    right: 0
}

.modal-pdpa .icon-spin span:before {
    height: 24px;
    width: 24px;
    display: inline-block;
    margin-left: 0 !important
}

.modal-pdpa+.modal-backdrop {
    background: rgba(231, 231, 231, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001
}

.modal-pdpa+.modal-backdrop.show {
    display: block
}

.modal-pdpa *,
.modal-pdpa ::after,
.modal-pdpa ::before {
    box-sizing: border-box
}

.modal-pdpa.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
    display: none;
    padding: 0 !important;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    font-family: arial, sans-serif;
    font-size: 14px;
    color: #454950;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.modal-pdpa .modal-backdrop {
    z-index: 1001
}

.modal-pdpa .modal-dialog {
    max-width: 396px;
    margin: 80px auto;
    position: relative;
    width: auto
}

.modal-pdpa .modal-dialog .modal-content {
    border: none;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(24, 24, 25, .16);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%
}

.modal-pdpa .modal-dialog .modal-footer,
.modal-pdpa .modal-dialog .modal-header {
    padding: 16px 24px
}

.modal-pdpa .modal-dialog .modal-header {
    background: #f4f5f8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
}

.modal-pdpa .modal-dialog .modal-title {
    font-size: 18px;
    font-weight: 700;
    display: block;
    width: 100%;
    line-height: inherit
}

.modal-pdpa .modal-dialog .modal-body {
    padding: 0;
    max-height: 600px;
    overflow: inherit !important;
    position: relative;
    flex: 1 1 auto
}

.modal-pdpa .modal-dialog .modal-footer {
    border-top: 1px solid #dedede;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.modal-pdpa .modal-dialog .modal-footer:before {
    display: none !important
}

.modal-pdpa .modal-dialog .modal-footer .btn+.btn {
    margin-left: 16px
}

.modal-pdpa .modal-dialog .modal-footer .float-custom {
    float: right
}

.modal-pdpa .modal-full {
    max-width: 1600px;
    margin-left: 24px;
    margin-right: 24px
}

.modal-pdpa .modal-xl {
    max-width: 912px
}

.modal-pdpa .modal-lg {
    max-width: 720px
}

.modal-pdpa .modal-md {
    max-width: 552px
}

.modal-pdpa .modal-open {
    overflow: hidden
}

.modal-pdpa .nav-custom .close {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 1
}

.modal-pdpa .nav-custom .close i {
    font-size: 24px
}

.modal-pdpa .nav-custom.has-btnclose>button {
    background: 0 0;
    border: none;
    padding: 0
}

.modal-pdpa .nav-custom .nav-tabs {
    text-align: center;
    border: none;
    clear: left;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
    overflow: inherit !important
}

.modal-pdpa .nav-custom .nav-tabs>a {
    float: left;
    padding: 16px;
    display: block;
    position: relative;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #454950
}

.modal-pdpa .nav-custom .nav-tabs>a:hover {
    text-decoration: underline
}

.modal-pdpa .nav-custom .nav-tabs>a.active:hover,
.modal-pdpa .nav-custom .nav-tabs>a.disabled:hover {
    text-decoration: none
}

.modal-pdpa .nav-custom .nav-tabs>a.active {
    position: relative;
    color: #434c58;
    font-weight: 700
}

.modal-pdpa .nav-custom .nav-tabs>a.active:before {
    position: absolute;
    background: #434c58;
    content: '';
    height: 3px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1
}

.modal-pdpa .nav-custom .nav-tabs>a.disabled {
    cursor: default
}

.modal-pdpa .nav-custom .nav-tabs .dropdown-menu {
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(24, 24, 25, .16)
}

.modal-pdpa .nav-custom .tab-content.border {
    border: 1px solid #dedede !important;
    padding: 24px;
    border-radius: 4px
}

.modal-pdpa .nav-custom .tab-content .tab-pane {
    display: none
}

.modal-pdpa .nav-custom .tab-content .tab-pane.active {
    display: block
}

.modal-pdpa .primary-bold {
    color: #28a745 !important;
    font-weight: 700
}

.modal-pdpa .primary-normal {
    color: #28a745 !important
}

.modal-pdpa .white-bold {
    color: #fff !important;
    font-weight: 700
}

.modal-pdpa .white-normal {
    color: #fff !important
}

.modal-pdpa .black-bold,
.modal-pdpa .black-normal {
    color: #454950 !important
}

.modal-pdpa .black-bold {
    font-weight: 700
}

.modal-pdpa .font-small {
    font-size: 12px;
    font-weight: 400
}

.modal-pdpa .font-normal {
    font-size: 14px
}

.modal-pdpa .nowrap {
    white-space: nowrap !important
}

.modal-pdpa .noborder {
    border: none !important
}

.modal-pdpa .pointer {
    cursor: pointer
}

.modal-pdpa .hide {
    display: none !important
}

.modal-pdpa .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.modal-pdpa .border-radius {
    border-radius: 4px
}

.modal-pdpa .font-regular,
.modal-pdpa .font-thin {
    font-weight: 400 !important
}

.modal-pdpa .font-bold {
    font-weight: 700 !important
}

.modal-pdpa .w-32 {
    width: 32px
}

.modal-pdpa .ml-8 {
    margin-left: 8px
}

.modal-pdpa .mr-8 {
    margin-right: 8px
}

.modal-pdpa .mt-8 {
    margin-top: 8px
}

.modal-pdpa .mb-8 {
    margin-bottom: 8px
}

.modal-pdpa .mb-2 {
    margin-bottom: 16px !important
}

.modal-pdpa .mb-3 {
    margin-bottom: 24px !important
}

.modal-pdpa .pl-8 {
    padding-left: 8px
}

.modal-pdpa .pr-8 {
    padding-right: 8px
}

.modal-pdpa .pt-8 {
    padding-top: 8px
}

.modal-pdpa .pt-3 {
    padding-top: 24px !important
}

.modal-pdpa .pb-8 {
    padding-bottom: 8px
}

.modal-pdpa .text-capitalize {
    text-transform: capitalize
}

.modal-pdpa .text-uppercase {
    text-transform: uppercase
}

.modal-pdpa .word-break {
    word-break: break-all
}

.modal-pdpa .min-w-auto {
    min-width: auto !important
}

.modal-pdpa .min-h-auto {
    min-height: auto !important
}

.modal-pdpa .max-h-320 {
    max-height: 320px
}

.modal-pdpa .w-100 {
    width: 100%;
    min-width: 100%
}

.modal-pdpa .d-flex-aligns-center {
    display: flex;
    align-items: center
}

.modal-pdpa .ml-auto {
    margin-left: auto
}

.modal-pdpa [class*=max-h-] {
    overflow: auto
}

@media (min-width:768px) {
    .modal-pdpa .nav-custom {
        height: 600px
    }
    .modal-pdpa .nav-custom .nav-tabs {
        padding: 24px 0;
        width: 175px;
        position: absolute;
        text-align: left;
        height: 100%;
        background: #f4f5f8;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: 1px solid #dedede;
        display: block
    }
    .modal-pdpa .nav-custom .nav-tabs>a {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px
    }
    .modal-pdpa .nav-custom .nav-tabs>a.active {
        background-color: #e7ecf0
    }
    .modal-pdpa .nav-custom .nav-tabs>a.active:before {
        right: inherit;
        top: 0;
        height: inherit;
        width: 2px
    }
    .modal-pdpa .nav-custom .tab-content {
        padding: 56px 0 24px 174px;
        height: 100%
    }
    .modal-pdpa .nav-custom .tab-content .tab-pane {
        padding: 0 24px;
        overflow-y: auto;
        max-height: 100%
    }
    .modal-pdpa .nav-custom .close {
        top: 24px;
        right: 24px
    }
    .modal-pdpa .d-md-block {
        display: block !important
    }
}

@media (min-width:576px) {
    .modal-pdpa .d-sm-none {
        display: none
    }
}

@media (max-width:767px) {
    .modal-pdpa .nav-custom {
        height: 100%;
        width: 100%;
        position: absolute;
        padding-top: 98px
    }
    .modal-pdpa .nav-custom .nav-tabs {
        background-color: #f4f5f8;
        position: absolute;
        top: 0;
        width: 100%;
        padding-top: 48px
    }
    .modal-pdpa .nav-custom .nav-tabs>a {
        max-width: 50% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }
    .modal-pdpa .nav-custom .tab-content {
        padding: 24px 0;
        height: 100%
    }
    .modal-pdpa .nav-custom .tab-content .tab-pane {
        padding: 0 16px;
        overflow-y: auto;
        max-height: 100%
    }
    .modal-pdpa .nav-custom .close {
        top: 16px;
        right: 16px
    }
    .modal-pdpa .nav-custom.has-btnclose .lang {
        right: 56px
    }
    .modal-pdpa .modal,
    .modal-pdpa .modal-content,
    .modal-pdpa .modal-dialog {
        height: 600px
    }
    .modal-pdpa .modal,
    .modal-pdpa .modal-open {
        overflow: hidden !important
    }
    .modal-pdpa .modal-dialog {
        margin: 0;
        max-width: 100%
    }
    .modal-pdpa .modal-dialog .modal-content {
        border-radius: 0;
        width: 100%
    }
    .modal-pdpa .modal-dialog .modal-body {
        max-height: 100%
    }
    .modal-pdpa .modal-dialog .modal-footer {
        padding-left: 16px;
        padding-right: 16px
    }
    .modal-pdpa .float-custom {
        float: none !important;
        width: 100% !important;
    }
    .modal-pdpa .float-custom .btn {
        float: left
    }
    .modal-pdpa .float-custom .btn+.btn {
        float: right;
        margin-left: 0
    }
    .modal-pdpa .lang {
        left: inherit;
        right: 8px;
        bottom: inherit;
        border-top: 0;
        padding: 0
    }
    .modal-pdpa .title-header small {
        margin-top: 0
    }
}

@font-face {
    font-family: icomoon-pdpa;
    src: url(../fonts-pdpa/icomoon.eot);
    src: url(../fonts-pdpa/icomoon.eot#iefix) format("embedded-opentype"), url(../fonts-pdpa/icomoon.ttf) format("truetype"), url(../fonts-pdpa/icomoon.woff) format("woff"), url(../fonts-pdpa/icomoon.svg#icomoon) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block
}

.modal-pdpa [class*=" icon-"],
.modal-pdpa [class^=icon-] {
    font-family: icomoon-pdpa !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.modal-pdpa .icon-pdpa-small-dropdown:before {
    content: "\e9abc"
}

.modal-pdpa .icon-pdpa-cancel:before {
    content: "\e911"
}

.modal-pdpa .icon-pdpa-globe .path1:before {
    content: "\e93e"
}

.modal-pdpa .icon-pdpa-globe .path2:before {
    content: "\e93f";
    margin-left: -1em
}